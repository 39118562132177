<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
        <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ this.$route.params.domain }}</h1>
            <!-- <h1 class="text-caption font-weight-light text-center">{{ domain.status }}</h1> -->
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5 px-10">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <template v-if="verification && verification.status === 'verified'">
                <v-alert dense type="success" border="left" elevation="2" class="mt-8">
                    Verified on {{ verificationStatusOnDisplay }}
                </v-alert>
                </template>

                <p class="text-overline mb-0">Domain dispute</p>

                <template v-if="errorDisputeNotFound && verification && verification.status === 'verified' && errorDomainNotFound">
                <!-- <p class="text-overline mb-0">Domain status</p> -->
                <p class="mb-0 pb-0">
                    You verified control of this domain, but it is currently assigned to another account.
                </p>
                <p class="mb-0 pb-0">
                    <v-btn color="purple" class="white--text" @click="createDomainDispute">Start dispute</v-btn>
                </p>
                </template>

                <template v-if="errorDisputeNotFound && (errorVerificationNotFound || !verification || verification.status !== 'verified')">
                <!-- <p class="text-overline mb-0">Domain status</p> -->
                <p class="mb-0 pb-0">
                    To dispute a domain, you must first verify control of it.
                </p>
                <p class="mb-0 pb-0">
                    <v-btn color="purple" class="white--text" :to="{ name: 'account-verify-domain', params: { domain: this.$route.params.domain, accountId: this.$route.params.accountId } }">Start verification</v-btn>
                </p>
                </template>

                <!-- <template v-if="dispute">
                    <pre>{{JSON.stringify(dispute)}}</pre>
                </template> -->

                <template v-if="domain">
                    <p>The domain is currently assigned to your account.</p>
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Manage domain</router-link>
                </template>

                <!-- <template v-if="errorDomainNotFound">
                    <v-alert dense type="warning" border="left" elevation="2" class="mt-8">
                        The domain is not currently assigned to you. Contact customer support.
                    </v-alert>
                </template> -->

                <!-- <template v-if="domain && domain.status === 'verified'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                    Verified
                </p> -->
                <!-- <p class="mb-0 pb-0">
                    You can remove the DNS record.
                </p> -->
                <!-- <p class="mb-0 pb-0 mt-8">
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.domain.name }}">Return to {{ domain.name }}</router-link>
                </p> -->
                <!-- </template> -->
                <!-- <template v-if="!domain || domain.status !== 'verified'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'warning-triangle']" class="amber--text"/>
                    Not verified
                </p>
                </template> -->

            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import TextLink from '@/components/TextLink.vue';
// import { toMillis } from '@/sdk/time';

export default {
    components: {
        // TextLink,
    },
    data: () => ({
        account: null,
        domain: null,
        dispute: null,
        verification: null,
        error: null,
        // verificationCode: null,
        // verificationCodeExpires: null,
        // verificationCodeNotAfter: null,
        timer: null,
        errorDomainNotFound: false,
        errorDisputeNotFound: false,
        errorVerificationNotFound: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        isViewReady() {
            return this.account !== null;
        },
        verificationStatusOnDisplay() {
            try {
                if (typeof this.verification?.status_on === 'number' && this.verification.status_on > 0) {
                    const date = new Date(this.verification.status_on);
                    return date.toString();
                }
                return 'Unknown';
            } catch (err) {
                console.log(`failed to parse verification status_on date: ${this.verification.status_on}`);
                return 'Unknown';
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomainDispute() {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { loadDomainDispute: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.getDispute({ domain: this.$route.params.domain });
                console.log(`loadDomainDispute: response ${JSON.stringify(response)}`);
                if (response) {
                    console.log(`loadDomainDispute: ${JSON.stringify(response)}`);
                    this.dispute = response;
                    // this.status = response.status;
                    // this.verificationCode = response.code;
                    // this.verificationCodeExpires = response.expires;
                    // this.verificationCodeNotAfter = response.not_after;
                    this.errorDisputeNotFound = false;
                }
            } catch (err) {
                console.log(`err.response? ${JSON.stringify(err.response)}`);
                if (err.response?.status === 404 || err.response?.code === 404) {
                    console.log('no domain dispute');
                    this.errorDisputeNotFound = true;
                } else {
                    console.error('failed to load domain dispute request', err);
                }
                // this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to get verification code' });
                // this.createDomainDispute();
                this.dispute = null;
            } finally {
                this.$store.commit('loading', { loadDomainDispute: false });
            }
        },
        async loadDomainVerificationRequest() {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { loadDomainVerificationRequest: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.getVerificationRequest({ domain: this.$route.params.domain });
                console.log(`loadDomainVerificationRequest: response ${JSON.stringify(response)}`);
                if (response) {
                    console.log(`loadDomainVerificationRequest: ${JSON.stringify(response)}`);
                    this.verification = response;
                    // this.status = response.status;
                    // this.verificationCode = response.code;
                    // this.verificationCodeExpires = response.expires;
                    // this.verificationCodeNotAfter = response.not_after;
                    this.errorVerificationNotFound = false;
                }
            } catch (err) {
                if (err.response?.status === 404 || err.response?.code === 404) {
                    console.log('no active domain verification request');
                    this.errorVerificationNotFound = true;
                } else {
                    console.error('failed to load domain verification request', err);
                }
                // this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to get verification code' });
                // this.createVerificationCode();
                this.verification = null;
            } finally {
                this.$store.commit('loading', { loadDomainVerificationRequest: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name: this.$route.params.domain });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                    this.errorDomainNotFound = false;
                }
            } catch (err) {
                if (err.response?.status === 403 || err.response?.code === 403) {
                    console.log('domain not found or not linked to this account');
                    this.errorDomainNotFound = true;
                } else {
                    console.error('failed to load domain request', err);
                }
                this.domain = null;
                // this.errorDomainNotFound = true;
                // this.error = true;
                // this.loadDomainDispute();
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async createDomainDispute() {
            try {
                this.$store.commit('loading', { createDomainDispute: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.createDispute({ domain: this.$route.params.domain });
                console.log(`createDomainDispute: response ${JSON.stringify(response)}`);
                if (response) {
                    this.dispute = response;
                    this.errorDisputeNotFound = false;
                }
            } catch (err) {
                if (err.response.status === 400 || err.response.code === 400) {
                    if (typeof err.response.data === 'object') {
                        const { error } = err.response.data;
                        if (error) {
                            switch (error) {
                            case 'DOMAIN_VERIFICATION_REQUIRED':
                                this.errorDomainVerificationRequired = true;
                                break;
                            case 'DOMAIN_NOT_FOUND':
                                // if server rejects dispute request because domain is not found, it means
                                // the user could just register the domain immediately.
                                console.log('DOMAIN NOT FOUND NEED TO CREATE DOMAIN');
                                this.createDomain();
                                break;
                            case 'DOMAIN_ALREADY_ASSIGNED':
                                // if server rejects dispute request because domain is already assigned
                                // to this account, try reloading it
                                this.loadDomain();
                                break;
                            case 'UNKNOWN':
                            default:
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain dispute', message: 'Unknown error' });
                                this.error = true;
                                break;
                            }
                        }
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain dispute', message: 'Server error' });
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain dispute' });
                }
            } finally {
                this.$store.commit('loading', { createDomainDispute: false });
            }
        },
        // NOTE: see also similar functions in VerifyDomain, CreateDomainVerify, CreateDomainRegister, PendingDomainList, DomainList, and CreateDomainDialog
        async createDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const request = {
                    // name: this.name,
                    name: this.$route.params.domain,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if domain arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the domain to an appropriate location after domain is created
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.create(request);
                console.log('createDomain response: %o', response);
                const { isCreated, error } = response;
                if (!isCreated && error) {
                    switch (error) {
                    case 'DOMAIN_VERIFICATION_REQUIRED':
                        this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } });
                        break;
                    case 'DOMAIN_ALREADY_CLAIMED':
                        // we only try to create the domain here if the server rejected the dispute request
                        // because the domain is NOT already assigned to anyone; so if we subsequently get this error
                        // that it's already assigned to another domain, reload the domain status
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain', message: 'Contact customer support' });
                        this.error = true;
                        break;
                    case 'UNKNOWN':
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain', message: 'Unknown error' });
                        this.error = true;
                        break;
                    }
                }
                if (isCreated) {
                    this.$router.push({ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                }
            } catch (err) {
                console.error('failed to create domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomainDispute();
        this.loadDomainVerificationRequest();
        this.loadDomain();
    },
    beforeRouteLeave(to, from, next) {
        if (this.timer) {
            console.log('beforeRouteLeave: clearing timer');
            clearTimeout(this.timer);
        }
        next();
    },
};
</script>
